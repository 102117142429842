<template>
  <div id="app">
    <router-view></router-view>

    <vue-audio loop autoplay :audio-source="bgm">
    </vue-audio>
    <!-- <div class="hide">
      <audio id="music1" controls="controls" autoplay="autoplay" loop hidden>
        <source src="../src/music/BGM.mp3"/>
      </audio>
      <audio id="music2" controls="controls" autoplay="autoplay" loop hidden>
        <source src="../src/music/bg.mp3" />
      </audio>
    </div> -->
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
export default {
  name: 'App',
  data() {
    return {
      bgm:'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/no.mp3',
    }
  },
  created(){
    this.$router.beforeEach((to,from,next)=>{
      let href = window.location.href.split('?'); 
      let toHref = to.fullPath.split('?');
      wx.miniProgram.postMessage({ data: {name: toHref[0]} });
      console.log(toHref[0])
      if(toHref[0]=='/page3'){

        this.bgm = href[1]==undefined?'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/hecheng.mp3':'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/no.mp3';

      }else if(toHref[0]=='/page3Home'){

        this.bgm = href[1]==undefined?'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/hecheng.mp3':'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/no.mp3';

      }else if(toHref[0]=='/page3Home2'){

        this.bgm = href[1]==undefined?'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/hecheng.mp3':'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/no.mp3';

      }else if(toHref[0]=='/page3Model'){

        this.bgm = href[1]==undefined?'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/hecheng.mp3':'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/no.mp3';

      }else if(toHref[0]=='/indexHome'){

        this.bgm = href[1]==undefined?'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/BGMhecheng.mp3':'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/no.mp3';

      }else if(toHref[0]=='/chk'){
        this.bgm = 'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/no.mp3';
      }else if(toHref[0]=='/page4Home2'){
        this.bgm = 'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/no.mp3';
      }else if(href[1]!=undefined){
        this.bgm = 'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/no.mp3';
      } else {
        this.bgm = 'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/BGMhecheng.mp3';
      }
      next();
    })
  },
  mounted() {  
    // document.addEventListener('visibilitychange', this.handleVisiable)  
  },  
  destroyed() {  
    // document.removeEventListener('visibilitychange', this.handleVisiable)  
  },  
  methods: {  
    // handleVisiable(e) {  
    //   switch(e.target.visibilityState) {
    //     case 'prerender':
    //       console.log('网页预渲染，内容不可见')
    //       break;
    //     case 'hidden':
    //       console.log('内容不可见，处理后台、最小化、锁屏状态')
    //       // alert('警告！你已经离开当前答题页面');
    //       this.bgm = 'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/no.mp3';
    //       break;
    //     case 'visible':
    //       console.log('处于正常打开')
    //       this.bgm = 'https://test-qihuan.oss-cn-hangzhou.aliyuncs.com/mp3/BGMhecheng.mp3';
    //       break;
    //   }
    // }
  }
  // mounted(){
    // let audio1 = document.getElementById('music1');
    // let audio2 = document.getElementById('music2');
    // audio1.play();
    // audio2.pause();
    // setTimeout(() => {
    //   audio1.pause()
    //   audio1.load()
    // }, 10)
    // $("html").one('touchstart',function(){
    //   audio1.play();
    // })
  // },
}

</script>

<style>
#app {
  max-width: 1000px; /* 设置最大宽度 */
  margin: 0 auto;   /* 居中显示 */
  height: 100vh; /* 设置容器高度为视口高度 */
}
.vueAudioBetter{
  position: fixed;
  width: 800px !important;
  z-index: 99999999;
  top: 0;
  display: none;
}
</style>
