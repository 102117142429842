import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router)


export default new Router({
    mode:'history',
    routes:[
        {
            path: "/chk",
            name: "index",
            component: () => import("@/views/index.vue")
        }, {
            path: "/indexHome",
            name: "indexHome",
            component: () => import("@/views/indexHome.vue")
        },{
            path: "/page1",
            name: "page1",
            component: () => import("@/views/page1.vue")
        },{
            path: "/page2",
            name: "page2",
            component: () => import("@/views/page2.vue")
        },{
            path: "/page3",
            name: "page3",
            component: () => import("@/views/page3.vue")
        },{
            path: "/page4",
            name: "page4",
            component: () => import("@/views/page4.vue")
        },{
            path: "/page4Home",
            name: "page4Home",
            component: () => import("@/views/page4Home.vue")
        },{
            path: "/page5",
            name: "page5",
            component: () => import("@/views/page5.vue")
        },{
            path: "/page6",
            name: "page6",
            component: () => import("@/views/page6.vue")
        },{
            path: "/page7",
            name: "page7",
            component: () => import("@/views/page7.vue")
        },{
            path: "/page3Home",
            name: "page3Home",
            component: () => import("@/views/page3Home.vue")
        },{
            path: "/page3Home2",
            name: "page3Home2",
            component: () => import("@/views/page3Home2.vue")
        },{
            path: "/page3Home3",
            name: "page3Home3",
            component: () => import("@/views/page3Home3.vue")
        },{
            path: "/page4Home2",
            name: "page4Home2",
            component: () => import("@/views/page4Home2.vue")
        },{
            path: "/page4Home3",
            name: "page4Home3",
            component: () => import("@/views/page4Home3.vue")
        }
    ]
})




