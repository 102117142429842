import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

// px2rem 自适应
import 'lib-flexible'

import router from './router'
import '../src/css/index.css'

import lottie from 'vue-lottie';
Vue.component('lottie', lottie)

import VueAudio from 'vue-audio-better'
Vue.use(VueAudio)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
